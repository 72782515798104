import { ReactElement, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useDependency } from "../../../contexts/DependencyContext";
import { TelemetryService } from "../../../services/telemetry/TelemetryService";
import ShoppingCartService from "../../../services/shoppingCart/ShoppingCartService";
import ShoppingCart from "../../../services/shoppingCart/ShoppingCart";
import { className } from "../../../shared/ClassName";
import isBrowser, { isSafari } from "../../../shared/IsBrowser";
import CtaLink from "../../Shared/CtaLink";
import CartItem from "./CartItem";
import CartTotal from "./CartTotal";
import CartStatusBar from "./CartStatusBar";
import { closeIcon, emptyCartIcon } from "../../Icons";
import * as styles from "./Cart.module.scss";

interface ICartProps {
    queryData: GatsbyTypes.strapi_Cart;
    shoppingCart?: ShoppingCart;
    visible: boolean;
    onClose: () => void;
}

export default function Cart({ queryData, shoppingCart, visible, onClose }: ICartProps): ReactElement {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const shoppingCartService = useDependency(ShoppingCartService);
    const telemetryService = useDependency(TelemetryService);

    useEffect(() => {
        if (isBrowser()) window.onbeforeunload = () => setLoading(false);
    }, []);

    useEffect(() => {
        let handle = 0;
        if (visible) {
            window.clearTimeout(handle);
            setShow(true);
        } else handle = window.setTimeout(() => setShow(false), 500);
        return () => window.clearTimeout(handle);
    }, [visible]);

    function handleClearAll(): void {
        shoppingCartService.createNewCart();
    }

    async function goToCheckoutAsync(): Promise<void> {
        if (!shoppingCart || loading) return;

        try {
            setLoading(true);

            const checkoutUrl = await shoppingCartService.executeCheckoutAsync();
            if (!checkoutUrl) {
                setLoading(false);
                return;
            }

            telemetryService.checkoutProducts(
                shoppingCart.items.map(x => ({
                    productName: x.productName,
                    licenseName: x.licenseName,
                    quantity: x.quantity,
                }))
            );

            await navigate(checkoutUrl);
            if (isSafari()) setLoading(false);
        } catch (error) {
            setLoading(false);
            telemetryService.error(error);
        }
    }

    return (
        <>
            {show && (
                <div {...className(styles.overlay, visible ? styles.show : styles.hide)} onClick={onClose}>
                    <div {...className(styles.container, visible ? styles.show : styles.hide)} onClick={e => e.stopPropagation()}>
                        <h1>{queryData?.title}</h1>
                        <button className={styles.closeIcon} onClick={onClose}>
                            {closeIcon}
                        </button>
                        {shoppingCart?.items && shoppingCart.items.length > 0 ? (
                            <>
                                <CartStatusBar queryData={queryData} onClearAll={handleClearAll} />

                                <div className={styles.itemsContainer}>
                                    {shoppingCart.items.map(x => (
                                        <CartItem key={`${x.productId}-${x.licenseId}`} cartItem={x} unitPriceLabel={queryData.unitPriceLabel ?? ""} />
                                    ))}
                                </div>

                                {loading && <div className={styles.loading}></div>}

                                <CartTotal
                                    queryData={queryData}
                                    subscriptionTotal={shoppingCart?.subscriptionTotal ?? 0}
                                    nonSubscriptionTotal={shoppingCart?.nonSubscriptionTotal ?? 0}
                                    total={shoppingCart?.total ?? 0}
                                    disableCheckout={loading}
                                    goToCheckout={goToCheckoutAsync}
                                />
                            </>
                        ) : (
                            <div className={styles.emptyCart}>
                                <div>{emptyCartIcon}</div>
                                <span>{queryData?.noProductsMessage}</span>
                                {queryData.linkToProducts && <CtaLink cta={queryData.linkToProducts} onClick={onClose} />}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
