import { ReactElement, useState, useEffect } from "react";
import CtaLink from "../../../../../Shared/CtaLink";
import { arrows } from "../../../../../Icons";
import * as styles from "./Tabs.module.scss";

interface ITabsProps {
    groupedSubMenuItems: { [key: string]: GatsbyTypes.strapi_ComponentSharedCtaContent[] };
}

export default function Tabs({ groupedSubMenuItems }: ITabsProps): ReactElement {
    const [activeTab, setActiveTab] = useState<string>();
    const keys = Object.keys(groupedSubMenuItems);

    useEffect(() => setActiveTab(keys.find(x => x)), []);

    function handleTabClick(event: React.MouseEvent, key: string) {
        event.stopPropagation();
        setActiveTab(key);
    }

    return (
        <>
            <nav className={styles.innerNav}>
                {keys
                    .filter(x => x)
                    .map(x => (
                        <button className={x === activeTab ? styles.isActive : ""} key={x} onClick={e => handleTabClick(e, x)}>
                            {x}
                        </button>
                    ))}

                {keys
                    .filter(x => !x)
                    .map(x =>
                        groupedSubMenuItems[x].map(y => (
                            <div key={y?.id} className={styles.allProducts}>
                                {
                                    <CtaLink key={y?.id} cta={y}>
                                        <button className={styles.productsBtn}>
                                            {y.text}
                                            <div>{arrows.right}</div>
                                        </button>
                                    </CtaLink>
                                }
                            </div>
                        ))
                    )}
            </nav>

            <nav className={styles.innerNavItems}>{activeTab && groupedSubMenuItems[activeTab].map(x => <div key={x?.id}>{<CtaLink key={x?.id} cta={x} />}</div>)}</nav>
        </>
    );
}
