import { ReactElement } from "react";
import { ModalFrame } from "../ModalFrame";
import { ModalContentProps } from "../../../../hooks/UseModal";
import { confirmPurchaseIcon, closeIcon } from "../../../Icons";
import * as styles from "./ConfirmPurchaseModal.module.scss";

export function ConfirmPurchaseModal(props: ModalContentProps<GatsbyTypes.strapi_ComponentSharedConfirmPurchase, boolean>): ReactElement {
    return (
        <ModalFrame useCard={true}>
            {
                <div className={styles.modalContent}>
                    <div className={styles.iconContainer}>{confirmPurchaseIcon}</div>
                    <h1>{props.heading}</h1>
                    <div dangerouslySetInnerHTML={{ __html: props.paragraph ?? "" }}></div>
                    <div>
                        <button onClick={() => props.onClose(true)}>{closeIcon}</button>
                        <button className={styles.accept} onClick={() => props.onClose(true)}>
                            {props.acceptButtonText}
                        </button>
                    </div>
                </div>
            }
        </ModalFrame>
    );
}
