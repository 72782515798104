import { ReactElement } from "react";
import * as styles from "./DebugInfo.module.scss";

export default function DebugInfo(): ReactElement {
    const buildCommit = process.env.GATSBY_BUILD_COMMIT;
    const buildDate = process.env.GATSBY_BUILD_DATE;
    const cmsUrl = process.env.GATSBY_CMS_URL;
    const buildCommitHash = buildCommit ? buildCommit.replace(/[a-z|A-Z|\-|_|0-9]*[\*]*\@/g, "") : undefined;
    const branch = buildCommit ? buildCommit.replace(/[\*]*\@[a-z|A-Z|\-|_|0-9]*/g, "") : undefined;

    return (
        <>
            {buildCommit && (
                <div className={styles.debugInfo}>
                    <span>
                        <b>Debug Info</b>
                    </span>
                    {cmsUrl && (
                        <span>
                            <b>CMS:</b>{" "}
                            <a href={`${cmsUrl}/admin`} target="_blank">
                                {cmsUrl}
                            </a>
                        </span>
                    )}
                    <span>
                        <b>Branch:</b>{" "}
                        <a href={`https://github.com/ControlBot/ddctoolbox/tree/${branch}`} target="_blank">
                            {branch}
                        </a>
                    </span>
                    <span>
                        <b>Commit:</b>{" "}
                        <a href={`https://github.com/ControlBot/ddctoolbox/commit/${buildCommitHash}`} target="_blank">
                            {buildCommitHash}
                        </a>
                    </span>
                    <span>
                        <b>Date:</b> {buildDate}
                    </span>
                </div>
            )}
        </>
    );
}
