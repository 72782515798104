import ShoppingCartItem from "./ShoppingCartItem";
import { showModal } from "../../hooks/UseModal";
import { AcceptModal } from "../../components/Shared/Modals/AcceptModal";

export default class ShoppingCart {
    items: ShoppingCartItem[] = [];

    total: number = 0;

    subscriptionTotal: number = 0;

    nonSubscriptionTotal: number = 0;

    taxes: string = "0.00";

    discount: string = "0.00";

    checkoutId: string = "";

    itemsQuantity: number = 0;

    add(
        product: GatsbyTypes.strapi_Product,
        licenseType: GatsbyTypes.strapi_ComponentProductLicenseType | undefined,
        tieredPricing: GatsbyTypes.strapi_ComponentProductTieredPricing[],
        price: number,
        quantity: number
    ): void {
        if (this.itemsQuantity + quantity > 999) {
            showModal(AcceptModal, { title: "Warning", content: "Exceeded cart maximum allowed quantity (999).", buttonText: "Accept", error: true });
            return;
        }

        const item = new ShoppingCartItem();
        item.productId = product.id;
        item.productName = product.name;
        item.productColor = product.color;
        item.licenseId = licenseType?.id ?? "";
        item.shopifyId = licenseType?.shopifyId ?? "";
        item.licenseName = licenseType?.license_type?.name ?? "";
        item.singularQtyText = licenseType?.license_type?.singularQtyText ?? "";
        item.pluralQtyText = licenseType?.license_type?.pluralQtyText ?? "";
        item.isSubscription = licenseType?.license_type?.isSubscription ?? false;
        item.quantity = quantity;
        item.unitPrice = price;
        item.totalPrice = price * quantity;
        item.tieredPricing = tieredPricing;

        this.items.push(item);
    }

    update(productId: string, licenseId: string, quantity: number, tieredPricing: GatsbyTypes.strapi_ComponentProductTieredPricing[]): void {
        if (quantity < 1) {
            this.remove(productId, licenseId);
            return;
        }

        const item = this.items.find(x => x.productId === productId && x.licenseId === licenseId);
        if (!item) return;

        if (this.itemsQuantity + quantity - item.quantity > 999) {
            showModal(AcceptModal, { title: "Warning", content: "Exceeded cart maximum allowed quantity (999).", buttonText: "Accept", error: true });
            return;
        }

        const pricing = tieredPricing.find(x => x.quantity && x.quantity <= quantity);
        item.unitPrice = pricing?.price ?? 0;
        item.totalPrice = quantity * item.unitPrice;
        item.quantity = quantity;
    }

    remove(productId: string, licenseId: string): void {
        const index = this.items.findIndex(x => x.productId === productId && x.licenseId === licenseId);
        if (index >= 0) this.items.splice(index, 1);
    }

    restore(storedCart: string): void {
        const storeCartJson = JSON.parse(storedCart) as ShoppingCart;
        this.checkoutId = storeCartJson?.checkoutId ?? "";
        this.items = storeCartJson?.items ?? [];
    }

    updateTotals() {
        const isSubscription = (item: ShoppingCartItem): boolean => item.licenseName.toLocaleLowerCase().includes("subscription");
        const reducer = (accumulatedTotal: number, item: ShoppingCartItem) => accumulatedTotal + item.totalPrice;

        this.itemsQuantity = this.items.reduce((accumulator, item) => accumulator + item.quantity, 0);
        this.subscriptionTotal = this.items.filter(isSubscription).reduce(reducer, 0);
        this.nonSubscriptionTotal = this.items.filter(x => !isSubscription(x)).reduce(reducer, 0);
        this.total = this.subscriptionTotal + this.nonSubscriptionTotal;
    }
}
