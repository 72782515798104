// extracted by mini-css-extract-plugin
export var dropdownList = "NavbarDesktop-module--dropdownList--JMBHi";
export var chevronDown = "NavbarDesktop-module--chevronDown--2EbJC";
export var hasSubItems = "NavbarDesktop-module--hasSubItems--nrqQT";
export var isOpened = "NavbarDesktop-module--isOpened--vjOUL";
export var dropdownAnchor = "NavbarDesktop-module--dropdownAnchor--WsnJM";
export var reveal = "NavbarDesktop-module--reveal--nZKSB";
export var hide = "NavbarDesktop-module--hide--kVkqi";
export var fadeInUp = "NavbarDesktop-module--fade-in-up--FI0U-";
export var slideInDown = "NavbarDesktop-module--slide-in-down--UsYeT";
export var slideInUp = "NavbarDesktop-module--slide-in-up--rdKhx";
export var moveRightToLeft = "NavbarDesktop-module--move-right-to-left--XgPkI";
export var moveLeftToRight = "NavbarDesktop-module--move-left-to-right--cCSRn";
export var dash = "NavbarDesktop-module--dash--D3XGs";