export default class ShoppingCartItem {
    /* product info */
    public productId: string = "";
    public productName: string = "";
    public productColor: string = "";

    /* license info */
    public shopifyId: string = "";
    public licenseId: string = "";
    public licenseName: string = "";
    public singularQtyText: string = "";
    public pluralQtyText: string = "";
    public isSubscription: boolean = false;
    public tieredPricing: GatsbyTypes.strapi_ComponentProductTieredPricing[] = [];

    /* purchase info */
    public quantity: number = 0;
    public unitPrice: number = 0;
    public totalPrice: number = 0;
}
