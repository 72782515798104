export default function isBrowser(): boolean {
    return typeof window !== "undefined";
}

export function isSafari(): boolean {
    return (
        isBrowser() &&
        typeof navigator !== "undefined" &&
        typeof navigator.vendor !== "undefined" &&
        navigator.vendor.indexOf("Apple") > -1 &&
        typeof navigator.userAgent !== "undefined" &&
        navigator.userAgent.indexOf("CriOS") === -1 &&
        navigator.userAgent.indexOf("FxiOS") === -1
    );
}
