import { ReactElement, useState } from "react";
import useWindowWidth from "../../../hooks/UseWindowWidth";
import useScrollListener, { ScrollDirection } from "../../../hooks/UseScrollListener";
import { className } from "../../../shared/ClassName";
import isBrowser from "../../../shared/IsBrowser";
import CtaLink from "../../Shared/CtaLink";
import { logoDDCToolBox, portalIcon, requestFeatureIcon, mobileMenuIcon, closeMenuIcon, cartIcon } from "../../Icons";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";
import * as styles from "./Header.module.scss";

interface HeaderProps {
    queryData: GatsbyTypes.strapi_Header;
    shoppingCartItems?: number;
    toggleShoppingCart: () => void;
}

export default function Header(props: HeaderProps): ReactElement {
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const isDesktop = useWindowWidth(769);
    const { position, direction } = useScrollListener(isBrowser() ? window : { scrollX: 0, scrollY: 0 });

    if (isBrowser()) {
        const headerVisibleOffset = isDesktop ? 140 : 125;

        if (position.y < headerVisibleOffset) {
            document.documentElement.classList.remove("navbarIsVisible");
            document.documentElement.classList.remove("navbarIsSticky");
        } else {
            document.documentElement.classList.add("navbarIsSticky");
            if (direction === ScrollDirection.Up) document.documentElement.classList.add("navbarIsVisible");
            else if (direction === ScrollDirection.Down) document.documentElement.classList.remove("navbarIsVisible");
        }
    }

    return (
        <header className={`noSelection ${styles.header}`}>
            <div className={styles.topHeader}>
                <div className={styles.innerBox}>
                    {props.queryData?.licensePortalCta && (
                        <CtaLink cta={props.queryData.licensePortalCta}>
                            {" "}
                            <div className={styles.iconContainer}>{portalIcon} </div>
                            {props.queryData.licensePortalCta.text}
                        </CtaLink>
                    )}
                    {props.queryData?.requestFeatureCta && (
                        <CtaLink cta={props.queryData.requestFeatureCta}>
                            {" "}
                            <div className={styles.iconContainer}>{requestFeatureIcon} </div>
                            {props.queryData.requestFeatureCta.text}
                        </CtaLink>
                    )}
                </div>
            </div>

            <div className={`mainBox ${styles.inner}`}>
                <CtaLink cta={{ link: "", text: "", id: "", external: false, iconSvg: undefined, group: "" }}>
                    <div className={styles.iconContainer}>{logoDDCToolBox}</div>
                </CtaLink>

                <div className={styles.menuWrapper}>
                    <div {...className(styles.menu, mobileMenuVisible ? styles.show : "")}>
                        {isDesktop ? (
                            <NavbarDesktop key="desktop" {...props} />
                        ) : (
                            <NavbarMobile key="mobile" onLinkClick={() => setMobileMenuVisible(false)} isVisible={mobileMenuVisible} {...props} />
                        )}
                    </div>

                    <button type="button" className={styles.iconButton} onClick={() => props.toggleShoppingCart()}>
                        {cartIcon} {!!props.shoppingCartItems && <span>{props.shoppingCartItems}</span>}
                    </button>

                    <button type="button" className={`${styles.iconButton} ${styles.mobileButton}`} onClick={() => setMobileMenuVisible(!mobileMenuVisible)}>
                        {mobileMenuVisible ? closeMenuIcon : mobileMenuIcon}
                    </button>
                </div>
            </div>
        </header>
    );
}
