import { ReactElement, useState, useEffect } from "react";
import { className } from "../../../../../shared/ClassName";
import CtaLink from "../../../../Shared/CtaLink";
import Tabs from "./Tabs";
import * as styles from "./SubMenu.module.scss";

interface ISubMenuProps {
    menuItemId?: string;
    subMenuItems: GatsbyTypes.strapi_ComponentSharedCtaContent[];
    activeSubMenuId?: string;
    onBackgroundClick: (e?: React.MouseEvent) => void;
}

export default function SubMenu({ menuItemId, subMenuItems, activeSubMenuId: subMenuActive, onBackgroundClick }: ISubMenuProps): ReactElement {
    const [groupedSubMenuItems, setGroupedSubMenuItems] = useState<{ [key: string]: GatsbyTypes.strapi_ComponentSharedCtaContent[] }>({});

    useEffect(() => {
        setGroupedSubMenuItems(
            subMenuItems.reduce(
                (result: any, item: GatsbyTypes.strapi_ComponentSharedCtaContent) => ({
                    ...result,
                    [item.group ?? ""]: [...(result[item.group ?? ""] || []), item],
                }),
                {}
            )
        );
    }, [subMenuItems]);

    function hasGroupedSubMenuItems() {
        const keys = Object.keys(groupedSubMenuItems);
        return keys.some(x => x !== "");
    }

    return (
        <>
            <div key={menuItemId} {...className(styles.dropdownContent, subMenuActive === menuItemId ? styles.isVisible : "")}>
                <div className="mainBox">
                    <div className={styles.innerDropDown} onClick={onBackgroundClick}>
                        {hasGroupedSubMenuItems() ? <Tabs groupedSubMenuItems={groupedSubMenuItems} /> : subMenuItems.map(x => <div key={x?.id}>{<CtaLink key={x?.id} cta={x} />}</div>)}
                    </div>
                </div>
            </div>
        </>
    );
}
