import React, { CSSProperties, ReactElement } from "react";
import { useDependency } from "../../../../contexts/DependencyContext";
import ShoppingCartItem from "../../../../services/shoppingCart/ShoppingCartItem";
import ShoppingCartService from "../../../../services/shoppingCart/ShoppingCartService";
import NumberInput from "../../../Shared/NumberInput";
import formatCurrency from "../../../../shared/FormatCurrency";
import { deleteIcon } from "../../../Icons";
import * as styles from "./CartItem.module.scss";

interface ICartItemProps {
    cartItem: ShoppingCartItem;
    unitPriceLabel: string;
}

export default function CartItem({ cartItem, unitPriceLabel }: ICartItemProps): ReactElement {
    const cartService = useDependency(ShoppingCartService);

    function removeCartItem(): void {
        cartService.removeCartItem(cartItem);
    }

    function handleChangeNumber(value: number | null): void {
        cartService.updateCartItem(cartItem, value ?? 0, cartItem.tieredPricing);
    }

    return (
        <div className={styles.product} style={{ "--product-color": cartItem.productColor } as CSSProperties}>
            <div className={styles.title}>
                <span>{cartItem.productName}</span>
            </div>
            <span className={styles.subtitle}>{cartItem.licenseName}</span>
            <div className={styles.seats}>
                <NumberInput label={cartItem.quantity === 1 ? cartItem.singularQtyText : cartItem.pluralQtyText} value={cartItem.quantity} onChange={handleChangeNumber} />
                <div className={styles.priceContainer}>
                    <span className={styles.price}>{formatCurrency(cartItem.totalPrice)}</span>
                    <span className={styles.unitPrice}>
                        {formatCurrency(cartItem.unitPrice)} {unitPriceLabel}
                    </span>
                </div>
            </div>
            <button onClick={removeCartItem} className={styles.deleteItem}>
                {deleteIcon}
            </button>
        </div>
    );
}
