import { ReactElement, useState, useEffect } from "react";
import CtaLink from "../../../Shared/CtaLink";
import isBrowser from "../../../../shared/IsBrowser";
import SubMenu from "./SubMenu";
import { chevron } from "../../../Icons";
import * as styles from "./NavbarMobile.module.scss";
import * as subMenuStyles from "./SubMenu/SubMenu.module.scss";

interface NavbarMobileProps {
    queryData: GatsbyTypes.strapi_Header;
    isVisible: boolean;
    onLinkClick: () => void;
}

export default function NavbarMobile({ queryData, isVisible, onLinkClick }: NavbarMobileProps): ReactElement {
    const [show, setShow] = useState(false);
    const [activeSubMenuId, setActiveSubMenuId] = useState<string>();
    const location = isBrowser() ? window.location.href : "";

    useEffect(() => onLinkClick(), [location]);

    useEffect(() => {
        const dropdowns = document.querySelectorAll(`.${subMenuStyles.dropdownContent}`) as NodeListOf<HTMLElement>;
        for (const dropdown of dropdowns) {
            dropdown?.style.setProperty("--dropdownContentHeight", `${dropdown.scrollHeight}px`);
        }
    }, [activeSubMenuId]);

    useEffect(() => {
        let handle = 0;

        if (isVisible) {
            document.body.classList.add("mobileDropdownOpen");
            window.clearTimeout(handle);
            setShow(true);
        } else {
            document.body.classList.remove("mobileDropdownOpen");
            handle = window.setTimeout(() => setShow(false), 500);
        }

        return () => window.clearTimeout(handle);
    }, [isVisible]);

    function handleDropdownClick(id: string): void {
        setActiveSubMenuId(id === activeSubMenuId ? "" : id);
    }

    return (
        <>
            {" "}
            {show && (
                <div>
                    <ul>
                        {queryData?.MenuItem?.map(x => (
                            <li key={x?.MenuItem?.id} className={styles.dropdownAnchor}>
                                <div className={styles.menuItem} onClick={() => handleDropdownClick(x?.MenuItem?.id || "")}>
                                    {<CtaLink key={x?.id} cta={x?.MenuItem as GatsbyTypes.strapi_ComponentSharedCtaContent} preventNavigation={(x?.SubMenuItem?.length ?? 0) > 0} />}
                                    {(x?.SubMenuItem?.length ?? 0) > 0 && <div>{activeSubMenuId === x?.MenuItem?.id ? chevron.up : chevron.down}</div>}
                                </div>

                                {x?.SubMenuItem && x.SubMenuItem.length > 0 && (
                                    <SubMenu
                                        menuItemId={x?.MenuItem?.id}
                                        subMenuItems={x.SubMenuItem as GatsbyTypes.strapi_ComponentSharedCtaContent[]}
                                        activeSubMenuId={activeSubMenuId}
                                        onLinkClick={onLinkClick}
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}{" "}
        </>
    );
}
