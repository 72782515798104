import { ReactElement } from "react";
import formatCurrency from "../../../../shared/FormatCurrency";
import * as styles from "./CartTotal.module.scss";

interface ICartTotalProps {
    queryData: GatsbyTypes.strapi_Cart;
    subscriptionTotal: number;
    nonSubscriptionTotal: number;
    total: number;
    disableCheckout: boolean;
    goToCheckout: () => void;
}

export default function CartTotal({ queryData, subscriptionTotal, nonSubscriptionTotal, total, disableCheckout, goToCheckout }: ICartTotalProps): ReactElement {
    return (
        <div className={styles.totals}>
            <div className={styles.totalsInner}>
                <div className={styles.subTotal}>
                    <div>
                        <span>{queryData?.subscriptionLabel}</span> <span>{formatCurrency(subscriptionTotal)}</span>
                    </div>
                    <div>
                        <span>{queryData?.nonSubscriptionLabel}</span> <span>{formatCurrency(nonSubscriptionTotal)}</span>
                    </div>
                </div>

                <hr />

                <div className={styles.total}>
                    <b>{queryData?.totalLabel}: </b> <span>{formatCurrency(total)}</span>
                </div>

                <div className={styles.checkout}>
                    <button type="button" onClick={goToCheckout} disabled={disableCheckout}>
                        {queryData?.checkoutButtonText}
                    </button>
                </div>
                <span className={styles.disclaimer}>{queryData.taxAndShippingText}</span>
            </div>
        </div>
    );
}
