import { ReactElement } from "react";
import * as styles from "./CartStatusBar.module.scss";

interface ICartStatusBarProps {
    queryData: GatsbyTypes.strapi_Cart;
    onClearAll: () => void;
}

export default function CartStatusBar({ queryData, onClearAll }: ICartStatusBarProps): ReactElement {
    return (
        <div className={styles.statusBar}>
            <div>{queryData.itemAddedToCartText}</div>
            <button className={styles.clearCart} onClick={onClearAll}>
                {queryData?.clearButtonText}
            </button>
        </div>
    );
}
