import { HttpClient, QueryString, HttpResponse, ContentTypeInterceptor } from "@miracledevs/paradigm-web-fetch";

export abstract class ApiClientBase {
    protected readonly httpClient: HttpClient;
    protected readonly baseUrl: string;

    protected constructor() {
        this.baseUrl = process.env.GATSBY_CMS_URL || "http://localhost:1337";
        this.httpClient = new HttpClient();
        //this.httpClient.registerInterceptor(new ContentTypeInterceptor("application/json"));
    }

    async get<TResult>(url: string, queryString?: QueryString): Promise<TResult> {
        return this.processResponse<TResult>(await this.httpClient.get(`${this.baseUrl}/${url}`, queryString));
    }

    async post<TResult>(url: string, queryString?: QueryString, body?: BodyInit): Promise<TResult> {
        return this.processResponse<TResult>(await this.httpClient.post(`${this.baseUrl}/${url}`, queryString, body));
    }

    async put<TResult>(url: string, queryString?: QueryString, body?: BodyInit): Promise<TResult> {
        return this.processResponse<TResult>(await this.httpClient.put(`${this.baseUrl}/${url}`, queryString, body));
    }

    async patch<TResult>(url: string, queryString?: QueryString, body?: BodyInit): Promise<TResult> {
        return this.processResponse<TResult>(await this.httpClient.patch(`${this.baseUrl}/${url}`, queryString, body));
    }

    async delete<TResult>(url: string, queryString?: QueryString): Promise<TResult> {
        return this.processResponse<TResult>(await this.httpClient.delete(`${this.baseUrl}/${url}`, queryString));
    }

    protected async processResponse<TResult>(response: HttpResponse): Promise<TResult> {
        const result = await response.json();

        if (!response.ok) {
            if (result && result.hasOwnProperty("message")) throw new Error(result.message);
            throw new Error(`The service responded with ${response.status} '${response.statusText}'`);
        }

        return result as TResult;
    }
}
