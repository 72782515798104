import { ReactElement, useRef, useState } from "react";
import useEventListener from "../../../../hooks/UseEventListener";
import { className } from "../../../../shared/ClassName";
import CtaLink from "../../../Shared/CtaLink";
import SubMenu from "./SubMenu";
import { chevron } from "../../../Icons";
import * as styles from "./NavbarDesktop.module.scss";

interface NavbarDesktopProps {
    queryData: GatsbyTypes.strapi_Header;
}

export default function NavbarDesktop(props: NavbarDesktopProps): ReactElement {
    const [activeSubMenuId, setActiveSubMenuId] = useState<string>();
    const listRef = useRef<HTMLUListElement>(null);

    useEventListener(document, "click", (e: MouseEvent) => {
        if (!listRef.current) return;
        if (listRef.current !== e.target && !listRef.current.contains(e.target as Node)) {
            setActiveSubMenuId("");
        }
    });

    function openMenu(id?: string): void {
        if (!id || id === activeSubMenuId) setActiveSubMenuId("");
        else setActiveSubMenuId(id);
    }

    function handleBackgroundClick(e?: React.MouseEvent): void {
        if (e?.target !== e?.currentTarget) {
            setActiveSubMenuId("");
        }
    }

    return (
        <ul ref={listRef} className={styles.dropdownList}>
            {props.queryData?.MenuItem?.map(x => (
                <li key={x?.MenuItem?.id} className={styles.dropdownAnchor} onClick={() => openMenu(x?.MenuItem?.id)}>
                    {<CtaLink key={x?.MenuItem?.id} cta={x?.MenuItem as GatsbyTypes.strapi_ComponentSharedCtaContent} preventNavigation={(x?.SubMenuItem?.length ?? 0) > 0} />}
                    <div {...className(styles.chevronDown, (x?.SubMenuItem?.length ?? 0) > 0 ? styles.hasSubItems : "", activeSubMenuId === x?.MenuItem?.id ? styles.isOpened : "")}>
                        {chevron.down}
                    </div>

                    {x?.SubMenuItem && x.SubMenuItem.length > 0 && (
                        <SubMenu
                            menuItemId={x?.MenuItem?.id}
                            subMenuItems={x.SubMenuItem as GatsbyTypes.strapi_ComponentSharedCtaContent[]}
                            activeSubMenuId={activeSubMenuId}
                            onBackgroundClick={handleBackgroundClick}
                        />
                    )}
                </li>
            ))}
        </ul>
    );
}
