import { ReactElement } from "react";
import NumericInput from "react-numeric-input";

interface INumberInput {
    label?: string;
    value?: number;
    preventKeyboardEvents?: boolean;
    onChange: (value: number | null) => void;
}

export default function NumberInput(props: INumberInput): ReactElement {
    return (
        <div>
            <label>{props.label}</label>
            <NumericInput min={1} max={999} step={1} value={props.value} onChange={props.onChange} snap mobile />
        </div>
    );
}
