import { ReactElement, PropsWithChildren, useState, useEffect, useCallback } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { DependencyProvider, useDependency } from "../../contexts/DependencyContext";
import { ModalContainer, showModal } from "../../hooks/UseModal";
import { useMessageListener } from "../../hooks/UseMessage";
import useUnhandledRejection from "../../hooks/UseUnhandledRejection";
import CartUpdatedMessage from "../../messages/CartUpdatedMessage";
import ShoppingCartService from "../../services/shoppingCart/ShoppingCartService";
import { TelemetryService } from "../../services/telemetry/TelemetryService";
import { ConfirmPurchaseModal } from "../Shared/Modals/ConfirmPurchaseModal";
import isBrowser from "../../shared/IsBrowser";
import Header from "./Header";
import Footer from "./Footer";
import Cart from "./Cart";
import CookiesConsent, { cookiesStorageItemKey } from "./CookiesConsent";
import * as styles from "./Layout.module.scss";

interface LayoutProps {
    pageTitle: string;
}

function removeNotAcceptedCookies() {
    const acceptedCookies = localStorage.getItem(cookiesStorageItemKey);
    if (!acceptedCookies || !JSON.parse(acceptedCookies).performance)
        document.cookie.split(";").forEach(c => (document.cookie = c.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)));
}

export default function Layout(props: PropsWithChildren<LayoutProps>): ReactElement {
    const cartService = useDependency(ShoppingCartService);
    const telemetryService = useDependency(TelemetryService);
    const layoutQueryData = useStaticQuery<GatsbyTypes.LayoutPageQuery>(query);
    const [cartVisible, setCartVisible] = useState(false);
    const cartUpdatedMessage = useMessageListener(CartUpdatedMessage);
    const unhandledExceptionCallback = useCallback((e: PromiseRejectionEvent) => telemetryService.error(e.reason), [telemetryService]);

    useUnhandledRejection(unhandledExceptionCallback);

    function toggleShoppingCart(visible?: boolean): void {
        if (visible !== undefined) {
            if (visible === cartVisible) return;
            setCartVisible(visible);
        } else setCartVisible(!cartVisible);
    }

    ///////////////////////////////////////////////////////
    // initializes the cart service on first render.
    ///////////////////////////////////////////////////////
    useEffect(() => {
        if (!isBrowser()) return;

        cartService.restoreCart();

        const urlParams = new URLSearchParams(window.location.search.toLowerCase());

        if (urlParams.get("action") === "confirmpurchase" && layoutQueryData.strapi.cart?.confirmPurchase && cartService.cartHasItems) {
            cartService.createNewCart();
            showModal(ConfirmPurchaseModal, layoutQueryData.strapi.cart.confirmPurchase as GatsbyTypes.strapi_ComponentSharedConfirmPurchase);
        }

        removeNotAcceptedCookies();
    }, []);

    ///////////////////////////////////////////////////////
    // toggles the shopping cart based on messaging.
    ///////////////////////////////////////////////////////
    useEffect(() => {
        if (!cartUpdatedMessage || cartUpdatedMessage.isRestore) return;
        toggleShoppingCart((cartUpdatedMessage?.shoppingCart && cartUpdatedMessage.shoppingCart.itemsQuantity > 0) ?? false);
    }, [cartUpdatedMessage?.shoppingCart?.itemsQuantity]);

    return (
        <DependencyProvider>
            <ModalContainer />
            <main className={styles.main}>
                <title>{props.pageTitle}</title>
                <Header
                    queryData={layoutQueryData.strapi.header as GatsbyTypes.strapi_Header}
                    toggleShoppingCart={toggleShoppingCart}
                    shoppingCartItems={cartUpdatedMessage?.shoppingCart?.itemsQuantity}
                />
                {props.children}
                <Footer queryData={layoutQueryData.strapi.footer as GatsbyTypes.strapi_Footer} />
                <Cart
                    queryData={layoutQueryData.strapi.cart as GatsbyTypes.strapi_Cart}
                    shoppingCart={cartUpdatedMessage?.shoppingCart}
                    onClose={() => toggleShoppingCart(false)}
                    visible={cartVisible}
                />
                <CookiesConsent queryData={layoutQueryData.strapi.privacyPolicy as GatsbyTypes.strapi_PrivacyPolicy} />
            </main>
        </DependencyProvider>
    );
}

const query = graphql`
    query LayoutPage {
        strapi {
            footer {
                FooterMenuItem {
                    MenuItem {
                        id
                        external
                        link
                        text
                        iconSvg
                    }
                    SubMenuItem {
                        id
                        external
                        link
                        text
                        iconSvg
                    }
                }
                contactInfo {
                    phones
                    addresses
                    id
                    title
                }
                copyrightText
            }
            header {
                title
                licensePortalCta {
                    text
                    link
                    external
                }
                requestFeatureCta {
                    text
                    link
                    external
                }
                MenuItem {
                    MenuItem {
                        id
                        link
                        text
                        external
                        iconSvg
                    }
                    SubMenuItem {
                        id
                        link
                        text
                        external
                        iconSvg
                        group
                    }
                }
            }
            cart {
                title
                clearButtonText
                noProductsMessage
                subscriptionLabel
                nonSubscriptionLabel
                unitPriceLabel
                totalLabel
                checkoutButtonText
                linkToProducts {
                    id
                    text
                    link
                    external
                }
                confirmPurchase {
                    acceptButtonText
                    heading
                    paragraph
                }
                taxAndShippingText
                itemAddedToCartText
            }
            privacyPolicy {
                notification {
                    base {
                        heading
                        paragraph
                    }
                    linkText
                    acceptText
                }
                settingsTitle
                settings {
                    id
                    name
                    base {
                        heading
                        paragraph
                    }
                    required
                }
            }
        }
    }
`;
