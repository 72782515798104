import { ReactElement, useState, useEffect } from "react";
import { className } from "../../../../../shared/ClassName";
import CtaLink from "../../../../Shared/CtaLink";
import { arrows } from "../../../../Icons";
import * as styles from "./SubMenu.module.scss";

interface ISubMenuProps {
    menuItemId?: string;
    subMenuItems: GatsbyTypes.strapi_ComponentSharedCtaContent[];
    activeSubMenuId?: string;
    onLinkClick: () => void;
}

export default function SubMenu({ menuItemId, subMenuItems, activeSubMenuId, onLinkClick }: ISubMenuProps): ReactElement {
    const [groupedSubMenuItems, setGroupedSubMenuItems] = useState<{ [key: string]: GatsbyTypes.strapi_ComponentSharedCtaContent[] }>({});

    useEffect(() => {
        setGroupedSubMenuItems(
            subMenuItems.reduce(
                (result: any, item: GatsbyTypes.strapi_ComponentSharedCtaContent) => ({
                    ...result,
                    [item.group ?? ""]: [...(result[item.group ?? ""] || []), item],
                }),
                {}
            )
        );
    }, [subMenuItems]);

    const keys = Object.keys(groupedSubMenuItems);

    return (
        <>
            <div key={menuItemId} {...className(styles.dropdownContent, activeSubMenuId === menuItemId ? styles.isVisible : "")}>
                <div>
                    <div className={styles.innerDropDown}>
                        {keys.some(x => x) ? (
                            <>
                                {keys
                                    .filter(x => !x)
                                    .map(x =>
                                        groupedSubMenuItems[x].map(y => (
                                            <div key={y?.id} className={styles.allProducts}>
                                                {
                                                    <CtaLink key={y?.id} cta={y} onClick={onLinkClick}>
                                                        <button className={styles.productsBtn}>
                                                            {y.text}
                                                            <div>{arrows.right}</div>
                                                        </button>
                                                    </CtaLink>
                                                }
                                            </div>
                                        ))
                                    )}

                                {keys
                                    .filter(x => x)
                                    .map((x, i) => (
                                        <div key={`${x}-${i}`}>
                                            {x && <span className={styles.subItemTitle}>{x}</span>}
                                            {groupedSubMenuItems[x].map(x => (
                                                <div key={x?.id}>{<CtaLink key={x?.id} cta={x} onClick={onLinkClick} />}</div>
                                            ))}
                                        </div>
                                    ))}
                            </>
                        ) : (
                            subMenuItems.map(x => <div key={x?.id}>{<CtaLink key={x?.id} cta={x} onClick={onLinkClick} />}</div>)
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
