// extracted by mini-css-extract-plugin
export var innerNav = "Tabs-module--innerNav--WfTu5";
export var isActive = "Tabs-module--isActive--42v8t";
export var allProducts = "Tabs-module--allProducts--0CJXK";
export var productsBtn = "Tabs-module--productsBtn--UZQoT";
export var innerNavItems = "Tabs-module--innerNavItems--3bFrs";
export var slideInUp = "Tabs-module--slide-in-up--NmOV5";
export var reveal = "Tabs-module--reveal--jzFvN";
export var hide = "Tabs-module--hide--Q2cwG";
export var fadeInUp = "Tabs-module--fade-in-up--hluQ8";
export var slideInDown = "Tabs-module--slide-in-down--aS8HL";
export var moveRightToLeft = "Tabs-module--move-right-to-left--AOjxf";
export var moveLeftToRight = "Tabs-module--move-left-to-right--Hl0U9";
export var dash = "Tabs-module--dash--vgAkX";