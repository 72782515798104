import { ContextCollection } from "../../../contexts/DependencyContext";
import { ApiClientBase } from "./ApiClientBase";

export class StrapiClient extends ApiClientBase {
    constructor() {
        super();
    }
}

ContextCollection.registerSingleton(StrapiClient);
