export class DeviceInformation {
    static get language(): string {
        return navigator.language;
    }
    static get platform(): string {
        return navigator.platform;
    }
    static get userAgent(): string {
        return navigator.userAgent;
    }
    static get resolutionWidth(): string {
        return window.innerWidth.toString();
    }
    static get resolutionHeight(): string {
        return window.innerHeight.toString();
    }
}
