import { ReactElement } from "react";
import { ModalFrame } from "../ModalFrame";
import { ModalContentProps } from "../../../../hooks/UseModal";
import { Card } from "../../Card";
import { closeIcon } from "../../../Icons";
import { circularErrorIcon, circularSuccessIcon } from "../../../Icons";
import * as styles from "./AcceptModal.module.scss";

export interface AcceptModalProps {
    title: string;
    content: string;
    buttonText: string;
    error?: boolean;
}

export function AcceptModal(props: ModalContentProps<AcceptModalProps, boolean>): ReactElement {
    return (
        <ModalFrame onClose={() => props.onClose(true)} allowClose useCard={false}>
            <div className={styles.modalContainer}>
                <Card>
                    <div className={styles.modalContent}>
                        <div className={styles.closeButton}>{closeIcon}</div>
                        <div className={styles.titleContainer}>
                            {props.error ? circularErrorIcon : circularSuccessIcon}
                            <h1 className={props.error ? styles.errorTitle : styles.successTitle}>{props.title}</h1>
                        </div>
                        <div className={styles.content}>{props.content}</div>
                    </div>
                </Card>
            </div>
        </ModalFrame>
    );
}
