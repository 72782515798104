import { ReactElement, useState, useEffect } from "react";
import SettingItem from "./SettingItem";
import * as styles from "./CookiesConsent.module.scss";

interface ICookiesConsentProps {
    queryData: GatsbyTypes.strapi_PrivacyPolicy;
}

export const cookiesStorageItemKey = "cookies-consent";

export default function CookiesConsent({ queryData }: ICookiesConsentProps): ReactElement {
    const [visible, setVisible] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [cookiesAcceptance, setCookiesAcceptance] = useState<{ [name: string]: boolean }>({});
    const notification = queryData.notification;

    useEffect(() => {
        const storageItem = localStorage.getItem(cookiesStorageItemKey);
        if (!storageItem && queryData.settings) {
            setVisible(true);
            const newCookiesAcceptance = Object.assign({}, ...queryData.settings.map(x => ({ [x?.name ?? ""]: true })));
            setCookiesAcceptance(newCookiesAcceptance);
        }
    }, []);

    function handleCheckedChange(name: string, checked: boolean) {
        setCookiesAcceptance({ ...cookiesAcceptance, [name]: checked });
    }

    function handleAccept() {
        localStorage.setItem(cookiesStorageItemKey, JSON.stringify(cookiesAcceptance));
        setVisible(false);
    }

    return (
        <>
            {visible && (
                <div className={styles.cookies}>
                    <div className="mainBox">
                        <h2>{notification?.base?.heading}</h2>
                        <div dangerouslySetInnerHTML={{ __html: notification?.base?.paragraph ?? "" }}></div>
                        {settingsVisible && (
                            <>
                                <h3>{queryData.settingsTitle}</h3>
                                {queryData.settings?.map(x => (
                                    <SettingItem
                                        key={x?.id}
                                        setting={x as GatsbyTypes.strapi_ComponentPrivacyPolicyCookieSetting}
                                        initialValue={cookiesAcceptance[x?.name ?? ""]}
                                        onCheckedChange={handleCheckedChange}
                                    />
                                ))}
                            </>
                        )}

                        <div className={styles.cookiesButtons}>
                            <button onClick={() => setSettingsVisible(!settingsVisible)}>{notification?.linkText}</button>
                            <button onClick={handleAccept}>{notification?.acceptText}</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
