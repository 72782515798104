import { ReactElement, PropsWithChildren } from "react";
import useEventListener from "../../../../hooks/UseEventListener";
import { className } from "../../../../shared/ClassName";
import { Card } from "../../Card";
import * as styles from "./ModalFrame.module.scss";

interface IModalFrameProps {
    useCard: boolean;
    allowClose?: boolean;
    className?: string;
    onClose?: () => void;
}

export function ModalFrame(props: PropsWithChildren<IModalFrameProps>): ReactElement {
    function closeWindow(): void {
        if (props.onClose && props.allowClose) {
            props.onClose();
        }
    }

    useEventListener(document, "keydown", (e: KeyboardEvent) => {
        e.cancelBubble = true;

        if (e.key === "Escape") {
            closeWindow();
        }
    });

    return (
        <div {...className(styles.modalOverlay, props.className ? props.className : "")} onClick={closeWindow}>
            <div className={styles.modalFrame}>{props.useCard ? <Card>{props.children}</Card> : props.children}</div>
        </div>
    );
}
