import { useEffect } from "react";

/**
 * Sets and removes event listeners
 *
 * @param {HTMLElement} [target] - Target element (window by default)
 * @param {string} event         - A string which represents the event to listen
 * @param {Function} callback    - The callback function for the event listener
 */
const useEventListener = (target: any = window, event: string, callback: Function) => {
    useEffect(() => {
        target.addEventListener(event, callback);
        return () => {
            target.removeEventListener(event, callback);
        };
    }, [target, event, callback]);
};

export default useEventListener;
