import { useEffect } from "react";

/**
 * Sets and removes window onunhandledrejection callback
 *
 * @param {(e: any) => void)} callback    - The callback function for the onunhandledrejection event
 */
const useUnhandledRejection = (callback: (e: PromiseRejectionEvent) => void) => {
    useEffect(() => {
        window.onunhandledrejection = callback;
    }, [callback]);
};

export default useUnhandledRejection;
