import { useRef, useState, useLayoutEffect, useEffect } from "react";
import isBrowser from "../shared/IsBrowser";
/**
 * Checks if the window matches any given width
 *
 * @param {number} width
 */

export default function useWindowWidth(width: number): boolean {
    const mediaQuery = useRef(isBrowser() ? window.matchMedia(`(min-width: ${width}px)`) : null);
    const [match, setMatch] = useState(mediaQuery.current?.matches ?? false);

    function handleMatchToggle({ matches }: MediaQueryListEvent | MediaQueryList): void {
        setMatch(matches);
    }

    useLayoutEffect(() => {
        if (!mediaQuery.current) return;

        const ref = mediaQuery.current;
        const supportsEventListener = ref && ref.addEventListener;
        handleMatchToggle(mediaQuery.current);

        // @ts-ignore: This condition will always return true since this function is always defined.
        if (supportsEventListener) ref.addEventListener("change", handleMatchToggle);
        else ref.addListener(handleMatchToggle);
        return () => {
            // @ts-ignore: This condition will always return true since this function is always defined.
            if (supportsEventListener) ref.removeEventListener("change", handleMatchToggle);
            else ref.removeListener(handleMatchToggle);
        };
    }, []);

    return match;
}
