import { ContextCollection } from "../../contexts/DependencyContext";
import ShoppingCart from "../shoppingCart/ShoppingCart";
import { StrapiClient } from "./clients/StrapiClient";

export class CartService {
    private readonly url: string;

    constructor(private readonly apiClient: StrapiClient) {
        this.url = "cart";
    }

    async checkoutAsync(shoppingCart: ShoppingCart): Promise<any> {
        const items = shoppingCart.items.map(x => ({
            productId: x.productId,
            licenseName: x.licenseName,
            shopifyId: x.shopifyId,
            quantity: x.quantity,
            isSubscription: x.isSubscription,
        }));

        const response = await this.apiClient.post<any>(`${this.url}/checkout`, {}, JSON.stringify(items));

        return response.cart;
    }
}

ContextCollection.registerSingleton(CartService, [StrapiClient]);
