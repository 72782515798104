import { ReactElement, useState } from "react";
import * as styles from "./SettingItem.module.scss";

interface ISettingItemProps {
    setting: GatsbyTypes.strapi_ComponentPrivacyPolicyCookieSetting;
    initialValue: boolean;
    onCheckedChange: (name: string, checked: boolean) => void;
}

export default function SettingItem({ setting, initialValue, onCheckedChange }: ISettingItemProps): ReactElement {
    const [selected, setSelected] = useState(initialValue);

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelected(event.target.checked);
        onCheckedChange(setting.name ?? "", event.target.checked);
    }

    return (
        <div className={styles.cookieSetting}>
            <div className={styles.cookieDisclaimer}>
                <h4>{setting.base?.heading}</h4>
                <div dangerouslySetInnerHTML={{ __html: setting?.base?.paragraph ?? "" }}></div>
            </div>
            <div className={styles.switchContainer}>
                <input type="checkbox" checked={selected} onChange={handleOnChange} disabled={setting.required} />
                <label></label>
            </div>
        </div>
    );
}
