import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ContextCollection } from "../../contexts/DependencyContext";
import isBrowser from "../../shared/IsBrowser";
import { cookiesStorageItemKey } from "../../components/Layout/CookiesConsent";
import { DeviceInformation } from "./DeviceInformation";

const TelemetryEvent = Object.freeze({
    Navigated: "navigated",
    Checkout: "checkout",
    BlogFilterChanged: "blogFilterChanged",
    ProductFilterChanged: "productFilterChanged",
    CheckoutProducts: "checkoutProducts",
});

export class TelemetryService {
    private appInsights: any;

    constructor() {
        this.initialize();
    }

    pageView(name: string) {
        this.sendPageView(name);
    }

    navigate(link: string) {
        this.sendEvent(TelemetryEvent.Navigated, { link });
    }

    checkoutProducts(products: any[]) {
        this.sendEvent(TelemetryEvent.Checkout, { products });
    }

    error(error: any) {
        this.sendError(error);
    }

    blogSearch(filter: { [name: string]: string }) {
        this.sendEvent(TelemetryEvent.BlogFilterChanged, filter);
    }

    productsSearch(filter: { [name: string]: string }) {
        this.sendEvent(TelemetryEvent.ProductFilterChanged, filter);
    }

    private initialize() {
        try {
            if (!isBrowser()) return;
            if (!process.env.GATSBY_APP_INSIGHTS_WEB_INSTRUMENTATION_KEY) {
                console.error("Insights instrumentation key not set");
                return;
            }

            const acceptedCookies = localStorage.getItem(cookiesStorageItemKey);

            this.appInsights = new ApplicationInsights({
                config: {
                    connectionString: process.env.GATSBY_APP_INSIGHTS_WEB_INSTRUMENTATION_KEY,
                    disableCookiesUsage: !acceptedCookies || !JSON.parse(acceptedCookies).performance,
                },
            });

            this.appInsights.loadAppInsights();
        } catch (e) {
            console.error(e);
        }
    }

    private sendPageView(pageName: string) {
        try {
            if (!this.appInsights) return;
            this.appInsights.trackPageView({ name: pageName, properties: { ...this.addDefaultProperties({}) } });
        } catch (e) {
            console.error(e);
        }
    }

    private sendEvent(eventName: string, properties: any) {
        try {
            if (!this.appInsights) return;
            this.appInsights.trackEvent({ name: eventName, properties: { ...this.addDefaultProperties(properties) } });
        } catch (e) {
            console.error(e);
        }
    }

    private sendError(error: any) {
        try {
            if (!this.appInsights) return;
            this.appInsights.trackException({ exception: error });
        } catch (e) {
            console.error(e);
        }
    }

    private addDefaultProperties(properties: any) {
        return {
            ...properties,
            language: DeviceInformation.language,
            platform: DeviceInformation.platform,
            userAgent: DeviceInformation.userAgent,
            resolution: `${DeviceInformation.resolutionWidth}x${DeviceInformation.resolutionHeight}`,
        };
    }
}

ContextCollection.registerSingleton(TelemetryService);
