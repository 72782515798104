import { ContextCollection } from "../contexts/DependencyContext";

interface IPosition {
    x: number;
    y: number;
}

export default class SettingsService {
    private scrollPosition: IPosition = { x: 0, y: 0 };

    private currentPage: string = "";

    private prevPage: string = "";

    private autoScrollOnlyIn: string[] = [];

    autoScrollEnabled: boolean = false;

    getScrollPosition(): IPosition {
        return this.scrollPosition;
    }

    getCurrentPage(): string {
        return this.currentPage;
    }

    getPrevPage(): string {
        return this.prevPage;
    }

    getAutoScrollOnlyIn(): string[] {
        return this.autoScrollOnlyIn;
    }

    autoScrollIsEnabled(): boolean {
        return this.autoScrollEnabled;
    }

    setScrollPosition(position: IPosition): void {
        this.scrollPosition = position;
    }

    setPrevPage(page: string): void {
        this.prevPage = page;
    }

    setCurrentPage(page: string): void {
        this.prevPage = this.currentPage;
        this.currentPage = page;
        this.autoScrollEnabled = this.autoScrollOnlyIn.includes(page) && this.prevPage === page;
    }
}

ContextCollection.registerSingleton(SettingsService, []);
