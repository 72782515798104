import { ReactElement, PropsWithChildren } from "react";
import { Link } from "gatsby";
import { useDependency } from "../../../contexts/DependencyContext";
import { TelemetryService } from "../../../services/telemetry/TelemetryService";
import removeWhiteSpaces from "../../../shared/RemoveWhiteSpaces";

interface ICtaLinkProps {
    cta: GatsbyTypes.strapi_ComponentSharedCtaContent;
    args?: { [name: string]: string };
    preventNavigation?: boolean;
    onClick?: () => void;
}

export default function CtaLink({ cta, args, children, preventNavigation, onClick }: PropsWithChildren<ICtaLinkProps>): ReactElement {
    const telemetryService = useDependency(TelemetryService);

    function handleClick(navigateUrl: string, e?: React.MouseEvent): void {
        preventNavigation && e?.preventDefault();
        !preventNavigation && telemetryService.navigate(navigateUrl);

        if (onClick) onClick();
    }

    function getLinkWithArgs() {
        if (!args) return cta.link;

        let linkWithArgs = cta.link;

        for (let key in args) {
            linkWithArgs = linkWithArgs.replace(`{${key}}`, removeWhiteSpaces(args[key]) ?? "");
        }

        return linkWithArgs;
    }

    const link = getLinkWithArgs();

    return cta.external ? (
        <>
            {cta.iconSvg ? <span dangerouslySetInnerHTML={{ __html: cta.iconSvg }}></span> : ""}
            <a
                href={link}
                target="_blank"
                onClick={() => {
                    handleClick(link);
                }}>
                {children ? children : cta.text}
            </a>
        </>
    ) : (
        <>
            {cta.iconSvg ? <span dangerouslySetInnerHTML={{ __html: cta.iconSvg }}></span> : ""}
            <Link
                to={`/${link}`}
                onClick={e => {
                    handleClick(link, e);
                }}>
                {children ? children : cta.text}
            </Link>
        </>
    );
}
