import { ContextCollection } from "../../contexts/DependencyContext";
import CartUpdatedMessage from "../../messages/CartUpdatedMessage";
import { CartService } from "../http/CartService";
import { MessageBusService } from "../messaging/MessageBus";
import ShoppingCart from "./ShoppingCart";
import ShoppingCartItem from "./ShoppingCartItem";

const cartStorageKey = "cart-storage";

export default class ShoppingCartService {
    private cart?: ShoppingCart;

    public get cartHasItems() {
        return (this.cart?.items && this.cart.items.length > 0) ?? false;
    }

    constructor(private readonly messageBus: MessageBusService, private readonly cartService: CartService) {}

    createNewCart(): void {
        this.cart = new ShoppingCart();
        localStorage.removeItem(cartStorageKey);
        this.updateCartTotalsAndSendMessage();
    }

    restoreCart(): void {
        const storedCart = localStorage.getItem(cartStorageKey);
        if (storedCart) {
            this.cart = new ShoppingCart();
            this.cart.restore(storedCart);
            this.updateCartTotalsAndSendMessage(true);
        } else this.createNewCart();
    }

    addItemToCart(product: GatsbyTypes.strapi_Product, licenseId: string, tieredPricing: GatsbyTypes.strapi_ComponentProductTieredPricing[], price: number, quantity: number): void {
        try {
            if (!this.cart) throw new Error("Cart was not initialized.");
            if (quantity < 1) return;

            const licenseType = product.licensesTypes?.find(x => x?.id === licenseId);
            const existingItem = this.cart.items.find(x => x.productId === product.id && x.licenseId === licenseId);

            if (existingItem) {
                this.updateCartItem(existingItem, existingItem.quantity + quantity, tieredPricing);
                return;
            }

            this.cart.add(product, licenseType, tieredPricing, price, quantity);
            localStorage.setItem(cartStorageKey, JSON.stringify(this.cart));
            this.updateCartTotalsAndSendMessage();
        } catch (error) {
            console.error(error);
        }
    }

    updateCartItem(cartItem: ShoppingCartItem, quantity: number, tieredPricing: GatsbyTypes.strapi_ComponentProductTieredPricing[]): void {
        try {
            if (!this.cart) throw new Error("Cart was not initialized.");
            this.cart.update(cartItem.productId, cartItem.licenseId, quantity, tieredPricing);
            localStorage.setItem(cartStorageKey, JSON.stringify(this.cart));
            this.updateCartTotalsAndSendMessage();
        } catch (error) {
            console.error(error);
        }
    }

    removeCartItem(cartItem: ShoppingCartItem): void {
        try {
            if (!this.cart) throw new Error("Cart was not initialized.");
            this.cart.remove(cartItem.productId, cartItem.licenseId);
            localStorage.setItem(cartStorageKey, JSON.stringify(this.cart));
            this.updateCartTotalsAndSendMessage();
        } catch (error) {
            console.error(error);
        }
    }

    async executeCheckoutAsync(): Promise<string | null> {
        if (!this.cart || this.cart.itemsQuantity > 999) return null;
        const shopifyCart = await this.cartService.checkoutAsync(this.cart);
        this.cart.checkoutId = shopifyCart.id;
        localStorage.setItem(cartStorageKey, JSON.stringify(this.cart));
        return shopifyCart.checkoutUrl;
    }

    private updateCartTotalsAndSendMessage(isRestore: boolean = false): void {
        if (!this.cart) return;
        this.cart.updateTotals();
        this.messageBus.send(new CartUpdatedMessage(this.cart, isRestore));
    }
}

ContextCollection.registerSingleton(ShoppingCartService, [MessageBusService, CartService]);
