import { ReactElement } from "react";
import DebugInfo from "./DebugInfo";
import CtaLink from "../../Shared/CtaLink";
import ContactInfo from "../../Shared/ContactInfo";
import { logoDDCToolBox, arrows } from "../../Icons";
import * as styles from "./Footer.module.scss";

interface FooterProps {
    queryData: GatsbyTypes.strapi_Footer;
}

export default function Footer(props: FooterProps): ReactElement {
    function scrollUp(): void {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <footer className={`noSelection ${styles.footer}`}>
                <button className={styles.buttonUp} onClick={scrollUp}>
                    {arrows.top}
                </button>
                <div className={`mainBox ${styles.inner}`}>
                    <div className={styles.column}>
                        <div className={styles.iconContainer}>{logoDDCToolBox}</div>
                        {props.queryData?.contactInfo?.map((x, i) => (
                            <ContactInfo {...(x as GatsbyTypes.strapi_ContactInfo)} key={i + "CI"} />
                        ))}
                    </div>

                    <div className={styles.footerItems}>
                        {props.queryData?.FooterMenuItem?.map((x, i) => (
                            <div className={styles.column} key={i + "div"}>
                                <span key={i + "span"}>{x?.MenuItem?.text}</span>
                                <ul>
                                    {x?.SubMenuItem?.map((y, j) => (
                                        <li key={j + "li"}>
                                            {" "}
                                            <CtaLink key={j} cta={y as GatsbyTypes.strapi_ComponentSharedCtaContent} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </footer>
            <div className={styles.copyRight}>
                <div className="mainBox">
                    <span>{props.queryData?.copyrightText}</span>
                </div>
            </div>

            <DebugInfo />
        </>
    );
}
